.checkbox-wrapper {
  --bg-color: transparent;
  --border-color: var(--border-secondary);

  display: block;
}

.container {
  display: flex;
  position: relative;
}

.label {
  display: flex;
  flex-direction: column;
  color: var(--content-primary-a);
}

.checkbox {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 16px;
  min-width: 16px;
  height: 16px;
  padding: 0;
  color: var(--content-primary-b-static);
  vertical-align: text-top;
  background-color: var(--bg-color);
  border: 1px solid var(--border-color);
  border-radius: 2px;
  transition: all 200ms;
  box-sizing: border-box;
}

.mt-4 {
  margin-top: 4px;
}

.checkbox-wrapper:hover .checkbox::after {
  --overlay-opacity: var(--hover-opacity);
}

.checkbox-wrapper .checkbox:active::after {
  --overlay-opacity: var(--pressed-opacity);
}

.checkbox-wrapper:active .checkbox::after {
  --overlay-opacity: var(--pressed-opacity);
}

.checkbox svg {
  width: 8px;
  height: 8px;
}

.checkbox-wrapper[data-selected] .checkbox,
.checkbox-wrapper[data-indeterminate] .checkbox {
  --bg-color: var(--background-accent-high);
  border: none;
}

.checkbox-wrapper[data-disabled],
.checkbox-wrapper[data-disabled] .checkbox,
.checkbox-wrapper[data-disabled] .label {
  cursor: not-allowed;
}

.checkbox-wrapper[data-disabled] .label {
  color: var(--content-secondary);
}

.checkbox-wrapper[data-disabled] .checkbox::after {
  --overlay-primary-a-color: var(--primary-white);
  --overlay-opacity: var(--disabled-opacity);

  top: -1px;
  left: -1px;
  width: 115%;
  height: 115%;
  background-color: var(--background-tertiary);
  border-radius: 2px;
}

.checkbox-wrapper[data-focus-visible] .checkbox {
  outline: none;
  box-shadow: 0 0 0 4px var(--border-accent-low);
}

.checkbox-wrapper-error[data-invalid] .checkbox {
  --border-color: var(--border-error);
}

.checkbox-wrapper-error[data-invalid] .label {
  color: var(--content-error);
}

.checkbox-wrapper-error[data-invalid][data-selected] .checkbox,
.checkbox-wrapper-error[data-invalid][data-indeterminate] .checkbox {
  --bg-color: var(--background-error-high);
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.checkbox-row {
  display: flex;
}

.error {
  font-size: var(--body-2-font-size);
  font-weight: var(--body-2-font-weight);
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-left: 24px;
  color: var(--content-error);
}

.errorMessage {
  line-height: var(--body-2-line-height);
}

.error svg {
  flex-shrink: 0;
}
