.status {
  display: inline-flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  flex-wrap: nowrap;
  align-items: center;
  padding: 3px 5px;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid var(--border-tertiary);
  color: var(--content-primary-a);
  background-color: var(--elevation-default);
}

.inactive {
  padding: 4px 6px;
  background-color: var(--background-secondary);
  border: 0;
}

.icon {
  display: flex;
}

.error {
  color: var(--content-error);
}

.in-progress {
  color: var(--content-secondary);
}

.validated {
  color: var(--content-success);
}

.waiting {
  color: var(--content-accent);
}

.warning {
  color: var(--content-warning);
}

.declined {
  color: var(--content-primary-a);
}
