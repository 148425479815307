.edit-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.quantity-container {
  display: flex;
}

.quantity-input {
  width: 140px;

  input {
    border-radius: 4px 0px 0px 4px;

    &:focus {
      border-radius: 4px;
      z-index: 1;
    }
  }
}

.unit-select {
  margin-left: -1px;
  margin-top: 28px;

  input {
    width: 104px;
    border-radius: 0px 4px 4px 0px;

    &:focus {
      border-radius: 4px;
    }
  }
}

div[data-combo-box-popover] {
  min-width: 104px;
}

.price-input {
  width: 161px;
}

.price-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
