.fieldset {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 0;
  max-width: 564px;
  padding: 0;
  border: none;
}

.subscription-period {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.automatic-number-section {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.automatic-number-label {
  line-height: 24px;
  color: var(--content-secondary);
}

.icon-tooltip {
  display: inline-flex;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
    vertical-align: middle;
  }
}

.date-picker {
  display: inline-block;
  flex: 1;
  white-space: normal;
}

.label {
  display: flex;
  column-gap: 8px;
  align-items: center;
  margin-bottom: 4px;
}

.header-text-field {
  flex-grow: 1;
}

.header-text-field-container {
  display: flex;
  gap: 4px;
  justify-content: space-between;
}

.header-text-field-add-button {
  composes: btn btn--tertiary from global;
  align-self: baseline;
}

.header-text-field-close-button {
  composes: btn btn--icon-only btn--tertiary btn--small from global;
  position: relative;
  top: 28px;
  display: flex;
  flex-basis: 32px;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.read-only-frequency {
  display: flex;
  gap: 8px;
}

.read-only {
  color: var(--content-secondary);
}

.invoiceStatusDropdownContainer {
  :global(.x-dropdown-button) {
    width: 100%;
  }

  :global(.x-dropdown-button__content__item[aria-current='true']) {
    background-color: transparent;
  }
}

.invoiceStatusDropdown {
  justify-content: space-between;
  width: 100%;
  padding: 0 12px;
  border-color: var(--border-secondary);
}
