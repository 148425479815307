.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 504px;
  height: 100%;
  text-align: center;
}

.lottieWrapper {
  width: 200px;
  padding: 20px 16px;
  margin-bottom: 32px;
}

.lottieIllustration {
  width: 170px;
}
