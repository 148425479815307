.dots-container {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 25px;
  position: absolute;
  width: 100%;
  bottom: 2px;
}

.dot {
  background-color: var(--content-tertiary);
  border-radius: 50%;
  cursor: pointer;
  height: 12px;
  width: 12px;
}

.dot-selected {
  background-color: var(--plum800);
}
