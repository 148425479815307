.chart-upsell-overlay {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-shrink: 0;
  gap: 24px;
  align-items: center;
  justify-content: flex-end;
  min-width: 559px;
  height: calc(100% - 30px);
  padding-left: 10px;
  background: linear-gradient(
    90deg,
    transparent 0%,
    var(--elevation-default) 50%,
    var(--elevation-default) 100%
  );
}

.chart-upsell-overlay-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 266px;
  text-align: right;
}

.tour-btn {
  width: auto;
}
