.title {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-top: 0;

  &.DE {
    font-size: 22px;
    line-height: 22px;
    min-height: 100px;
  }
}
