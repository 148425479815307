.card {
  flex: 1;
  color: var(--content-primary-a);
  max-width: 340px;
}

.illustration-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 170px;
  padding: 25px;
  border-radius: 8px;
}

.card.compliant .illustration-wrapper {
  background-color: var(--mint50);
}

.card.flexible .illustration-wrapper {
  background-color: var(--plum50);
}

.card.integrated .illustration-wrapper {
  background-color: var(--mustard50);
}

.card.accelerate-efficiency .illustration-wrapper {
  background-color: var(--peach50);
}

.card.keep-track .illustration-wrapper {
  background-color: var(--mustard50);
}

.card.boost-bookkeeping .illustration-wrapper {
  background-color: var(--mint50);
}

.illustration {
  width: 120px;
  height: 120px;
}

.list {
  padding: 0;
  list-style: none;
}

.list > li {
  display: flex;
  align-items: center;
}

.list > li:not(:last-child) {
  margin-bottom: 8px;
}

.list > li > svg {
  flex-shrink: 0;
}

.mr-12 {
  margin-right: 12px;
}
