.detail {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.card {
  background: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reference {
  background: var(--background-tertiary);
  height: 48px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
}

.payment {
  height: 116px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.separator {
  border-bottom: 1px solid var(--border-tertiary);
}

.date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}

.cta {
  display: flex;
  gap: 8px;
}
