.thead {
  color: var(--invoice-color-text-alt);
  white-space: nowrap;
  background: var(--invoice-color-theme);

  th {
    overflow: hidden;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 4px 6px;
  }

  th:first-child,
  th:last-child {
    padding: 4px 12px;
  }
}

.description {
  width: 312px;
  text-align: left;
}

.description-de {
  width: 257px;
  text-align: left;
}

.quantity {
  max-width: 50px;
  text-align: right;
}

.amount {
  max-width: 90px;
  text-align: right;
}

.vat {
  max-width: 60px;
  text-align: right;
}

.subtotal {
  max-width: 100px;
  text-align: right;
}
