.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 360px;
  padding: 24px;
  overflow: hidden;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 240px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.header.loading {
  padding: 8px 0 11px;
}

.header-title {
  composes: title-4 from global;
  color: var(--content-primary-a);
}

.invoices-list {
  width: 100%;
  padding-left: 0;
  animation: fadeIn 0.25s ease-out;
}

.invoices-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  list-style: none;

  &:hover {
    cursor: pointer;
    background-color: var(--state-primary-a-hover);
  }
}

.invoices-list-item.loading {
  &:hover {
    cursor: default;
    background-color: unset;
  }
}

.invoices-list-item-link {
  color: initial;
  text-decoration: none;
  background-color: initial;
}

.icon {
  width: 12px;
  height: 12px;
  margin-left: 8px;
}

.icon-warning {
  composes: icon;

  /* !important required to override <Tooltip> btn/svg color style */
  color: var(--content-warning) !important;
}

.icon-error {
  composes: icon;
  color: var(--content-error);
}

.name-status {
  flex: 2;
  min-width: 0;
  margin-left: 16px;
}

.empty-avatar {
  width: 32px;
  height: 32px;
  background-color: var(--background-secondary);
  border-radius: 50%;
}

.justify-end {
  justify-items: flex-end;
}

.amount {
  justify-content: flex-end;
}

.name,
.amount {
  composes: body-1 from global;
  display: flex;
}

.name-title {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.amount.loading,
.name.loading {
  padding-bottom: 2px;
}

.date {
  text-align: right;
}

.date,
.status {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.date.overdue {
  color: var(--content-error);
}

.missing-info {
  color: var(--content-tertiary);
}

.empty {
  composes: wrapper;
  gap: 0;
  align-items: center;
  padding: 0;
  text-align: center;
}

.empty-header {
  width: 100%;
  min-height: 188px;
  background: var(--elevation-low);
}

.empty-state {
  width: auto;
  height: 100%;
}

.empty-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 8px;
  background-color: var(--elevation-high);
}

.empty-title {
  composes: title-4 from global;
  margin-bottom: 4px;
  color: var(--content-primary-a);
}

.empty-description {
  composes: body-2 from global;
  margin-bottom: 16px;
  color: var(--content-secondary);
}

.cta-btn {
  width: fit-content;
  margin-top: 24px;
}

.cta-btn.loading {
  margin-top: 27px;
}

.empty .cta-btn {
  margin-top: 0;
}

.mt0 {
  margin-top: 0;
}

.contents {
  display: contents;
}

.error-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  align-self: center;
  margin-top: 24px;
}

.error-message {
  composes: body-1 from global;
  display: flex;
  gap: 12px;
  align-items: center;
  color: var(--content-secondary);

  > svg {
    color: var(--content-warning) !important;
  }
}

.error-cta {
  composes: btn btn--secondary from global;
  display: flex;
  gap: 8px;
}
