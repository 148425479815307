.row {
  &:not(.no-bottom) {
    border-bottom: 1px solid var(--gray300);
  }

  td {
    padding: 12px;
    text-align: right;
    white-space: nowrap;

    &:first-child {
      text-align: left;
      white-space: normal;
    }

    .title {
      margin: 2px 0;
    }

    .description {
      color: var(--invoice-color-text-66);
      white-space: pre-line;
    }
  }
}
