.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 504px;
  margin: 48px auto;
}

.title,
.reminder,
.cancel {
  text-align: center;
}

.hidden {
  visibility: hidden;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--elevation-low);
}

.purple {
  color: var(--content-accent);
}
