.totals {
  width: 296px;
  margin-right: 0;
  margin-left: auto;
  border-collapse: collapse;

  td {
    padding: 4px 12px;
    text-align: right;
    vertical-align: baseline;
  }

  td:first-child {
    text-align: left;
  }

  td:last-child {
    white-space: nowrap;
  }

  tr:last-child,
  .total-amount {
    background: var(--invoice-color-theme-05);
  }
}
