.value-container {
  position: relative;
  display: block;

  .unlimited-value {
    position: absolute;
    top: 30px;
    left: 25px;
    display: block;
    padding: 10px 20px;
    cursor: text;
    background: var(--elevation-default);
  }
}
