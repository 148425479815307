@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/**
 * This case is needed in case of unexpected number of colunms
 * so the chartoverlay fallbacks to always be visible.
 */
.visible {
  opacity: 1;
}

.fadein {
  opacity: 0;
}

.fadeIn-5-col {
  --number-of-animated-columns: 3;

  composes: fadein;
  animation: fadeIn calc(var(--number-of-animated-columns) * 0.5s) ease-in forwards;
}

.fadeIn-7-col {
  --number-of-animated-columns: 5;

  composes: fadein;
  animation: fadeIn calc(var(--number-of-animated-columns) * 0.5s) ease-in forwards;
}
