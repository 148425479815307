.balanceTable {
  composes: caption-bold from global;
  width: 100%;
  color: var(--content-primary-a);
  table-layout: fixed;
  border-spacing: 0;

  /* Visually hide thead but leave it for accessibility */
  thead,
  thead th {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    font-size: 0;
    line-height: 0;
    visibility: hidden;
    border: 0;
  }

  col {
    width: 100%;
    min-width: 100px;
  }

  col:first-of-type {
    width: 264px;
  }

  tbody :is(td, th) {
    position: relative;
    height: 56px;
    padding: 16px 16px;
    background-color: var(--elevation-low);
    isolation: isolate;

    &.current {
      background-color: var(--background-tertiary);
    }
  }
}

.rowHeader {
  composes: caption-bold from global;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px 0 0 4px;
}

.rowCell {
  border-top: 1px solid var(--border-tertiary);
  border-bottom: 1px solid var(--border-tertiary);
}

.rowCell:last-child {
  border-right: 1px solid var(--border-tertiary);
  border-radius: 0 4px 4px 0;
}

.forecastAmount {
  max-width: 100%;
  padding-right: 1px;
  overflow-x: hidden;
  font-style: italic;
  color: var(--content-secondary);
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
