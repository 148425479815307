.marketingTestimonial {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  text-align: left;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;

  .footer {
    display: flex;
    gap: 12px;

    img {
      width: 32px;
      aspect-ratio: 1 / 1;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
