.item-row {
  border-bottom: 1px solid var(--border-tertiary);

  td {
    padding: 16px;
    vertical-align: middle;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .item-cell {
    text-align: left;
  }

  .item-description {
    margin-top: 4px;
    color: var(--content-secondary);
    font-size: var(--caption-font-size);
    font-weight: var(--caption-font-weight);
    line-height: var(--caption-line-height);
  }

  .quantity-subtext {
    display: block;
    color: var(--content-secondary);
    margin-top: 4px;
  }

  .unit-price-subtext {
    color: var(--content-secondary);
    margin-top: 4px;
  }

  .quantity-cell,
  .vat-cell,
  .total-cell {
    text-align: right;
    color: var(--content-primary);
  }

  .quantity-cell,
  .vat-cell {
    display: none;
  }

  @media only screen and (width >= 768px) {
    td {
      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }

    .vat-cell {
      display: revert;
    }

    .item-description {
      font-size: var(--body-2-font-size);
      font-weight: var(--body-2-font-weight);
      line-height: var(--body-2-line-height);
    }
  }

  @media only screen and (width >= 1369px) {
    td {
      padding: 16px;
    }

    .quantity-subtext {
      display: none;
    }

    .quantity-cell,
    .vat-cell {
      display: revert;
    }
  }

  /* Preview mode needs to apply desktop styles regardless of screen size */
  &.preview {
    td {
      padding: 16px;

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }

    .quantity-subtext {
      display: none;
    }

    .quantity-cell,
    .vat-cell {
      display: revert;
    }

    .item-description {
      font-size: var(--body-2-font-size);
      font-weight: var(--body-2-font-weight);
      line-height: var(--body-2-line-height);
    }
  }
}
