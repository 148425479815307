.chart-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.x-axis-tick-line {
  stroke: var(--border-tertiary);
  stroke-width: 1;
}

.x-axis-tick-text,
.y-axis-tick-text {
  fill: var(--content-tertiary);
  font-size: 12px;
}

.today-circle {
  fill: var(--dataviz-balance, #9b81f6);
}

.grey-circle {
  fill: var(--border-secondary);
}

.selected-circle {
  fill: var(--elevation-default);
}

.selected-circle-border {
  stroke: var(--border-tertiary);
  fill: var(--elevation-default);
}

.outflow-bar {
  fill: var(--dataviz-outflows, #fa7272);
}

.inflow-bar {
  fill: var(--dataviz-inflows, #54d59f);
}

.skeleton-bar {
  fill: var(--gray300);
  animation: skeleton-shimmer 1.5s infinite linear;
}

@keyframes skeleton-shimmer {
  0% {
    fill: var(--gray300);
  }
  50% {
    fill: var(--gray400);
  }
  100% {
    fill: var(--gray300);
  }
}

.highlighted-line path {
  stroke: var(--dataviz-balance, #9b81f6);
}

.grey-line path {
  stroke: var(--border-secondary, #b8b8b5);
}
