.container {
  display: flex;
  flex-direction: column;
  min-width: 484px;
  height: 220px;
  padding: 24px;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  box-shadow: var(--shadow-low);
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  display: flex;
  align-items: center;
}

.conditions {
  display: grid;
  grid-template-columns: 1fr 0.85fr 1.15fr;
  grid-gap: 16px;
  margin: 24px 0;
}
