.container {
  width: 100%;
  padding: 40px 16px;
  display: flex;
  flex-direction: column;

  .form {
    margin-bottom: 48px;
  }

  .basket {
    margin-bottom: 24px;
  }

  .mobile-footer {
    display: revert;
  }

  .desktop-footer {
    display: none;
  }

  @media only screen and (width >= 768px) {
    width: 504px;
    padding: 80px 0 48px;
    margin: 0 auto;

    .form {
      margin-bottom: 24px;
    }
  }

  @media only screen and (width >= 1369px) {
    width: 100%;
    padding: 0;
    margin: 0;
    flex-direction: row;

    .basket {
      padding: 80px 48px 72px;
      margin-bottom: 0;
      flex: 1;
      display: flex;
      justify-content: center;
    }

    .form {
      padding: 80px 48px 72px;
      background: var(--elevation-low);
      width: 694px;
      margin-bottom: 0;
    }

    .mobile-footer {
      display: none;
    }

    .desktop-footer {
      display: revert;
      margin-top: 48px;
    }
  }
}
