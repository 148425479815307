.condition {
  margin-top: 0;
  white-space: pre-line;
}

.termsAndConditionsLink {
  color: var(--content-accent);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--content-accent);
  }
}
