.pageContainer {
  position: relative;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.firstSectionContainer {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  min-height: 296px;
  opacity: 0;
  transform: translateY(-50px);
}

.showWithoutAnimation {
  opacity: 1;
  transform: translateY(0);
}

.animate {
  animation: slideDownFade 0.8s ease-out forwards;
}

@keyframes slideDownFade {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.ctaWrapper {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.textWrapper {
  max-width: 591px;
  text-align: center;
}

.illustration {
  width: 856px;
  height: 456px;
}

.fullScreenAnimation {
  width: 100%;
  height: 100%;
}
