.header {
  position: absolute;
  top: -80px;
  z-index: var(--z-index-default);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  width: 100%;
  padding: 16px 24px;
  background-color: var(--elevation-high);
  box-shadow: var(--shadow-high);
  transition: top 0.2s ease;

  &.visible {
    top: 0;
  }
}

.colLeft,
.colCenter,
.colRight {
  display: flex;
  align-items: center;
}

.colCenter {
  justify-content: center;
}

.colRight {
  justify-content: flex-end;
}

.logo {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border-radius: 50%;
}
