.layout, .de-layout {
  width: 100%;
  margin-top: auto;
}

.layout {
  padding: 24px 48px;
  background-color: var(--invoice-color-theme-05);
}

.de-layout {
  padding: 16px 67px 40px 84px;
  background-color: var(--primary-white);

  .paymentLink {
    margin-bottom: 58px;
  }
}

.divider {
  margin-top: 16px;
  width: 100%;
  height: 1px;
  background-color: var(--gray300);
}
