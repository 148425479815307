.disclaimers {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.stamp-duty-disclaimer {
  width: 296px;
  margin-right: 0;
  margin-left: auto;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: var(--invoice-color-text-66);
  text-align: right;
}

.draft-disclaimer {
  color: var(--invoice-color-text-66);
}
