.container {
  --horizontal-padding: 16px;

  margin-left: calc(-1 * var(--horizontal-padding));
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-high);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 12px var(--horizontal-padding);
  overflow-x: auto;
  background-color: var(--elevation-low);
}

.header-button {
  width: 32px;
  height: 32px;
}

.section {
  width: 100%;
  max-height: 270px;
  padding: 8px var(--horizontal-padding);
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid var(--border-tertiary);
}

.section:first-child {
  border-top: none;
}

.chevron {
  flex-shrink: 0;
  color: var(--content-secondary);
}

.button {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  text-align: left;

  &:global(.overlay[disabled]::after) {
    --overlay-primary-a-color: transparent;
  }
}
