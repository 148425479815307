.container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
}

.title {
  composes: body-1 from global;
  color: var(--content-primary-a);
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.content {
  margin-right: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.compact {
  gap: 8px;
  .content {
    max-width: 98px;
  }
}

.avatarContainer {
  position: relative;
}

.recurringIcon {
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 16px;
  height: 16px;
  background-color: #9b81f6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 8px;
    stroke-width: 2.5px;
    stroke: white;
  }
}
