.table {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 29px;
}

.labels {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 153px;
}

.details {
  display: flex;
  justify-content: space-between;
  width: 273px;
}

.totals {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 206px;
  margin-left: auto;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mb-22 {
  margin-bottom: 22px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-15 {
  margin-left: 15px;
}
