.main {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  flex-grow: 1;
  overflow: auto;
  -ms-overflow-style: none;

  &:has([data-is-dragging]) {
    overflow-x: hidden;
  }
}

.container :global(.pinned-column.pinned-column-shadow) {
  clip-path: inset(0 -9999px 0 0);
  box-shadow: var(--shadow-high);
}

.container :global(.row-highlight) > td {
  background-color: var(--background-tertiary);
}

.loading-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.table-loading {
  width: 100%;
  border-spacing: 0;
}

.pagination-loading {
  margin-top: auto;
}

.empty-loading {
  flex-grow: 1;
}
