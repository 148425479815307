.settingsWrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.website {
  width: 100%;
}

.disclaimer {
  margin-top: 15px;
}

.typeSelector {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-low);

  &:not(&.readonly):hover {
    box-shadow: var(--shadow-high);
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px var(--border-accent-low);
  }
}

.typeSelector * {
  cursor: pointer;
}

.types {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  width: inherit;
  padding: 16px;
  border-top: 1px solid var(--border-tertiary);
}

.options {
  display: flex;
  flex-direction: row;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.typeHeader {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  padding: 15px;
  text-decoration: unset;
  appearance: none;
  outline: none;
  transition: all var(--transition-duration-short) ease-in-out;
}

.typeText {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
