.container {
  padding: 8px 0;
}

.terms-conditions {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--invoice-color-text-66);
  font-size: 10px;
  line-height: 16px;
}

.terms-conditions-size-9 {
  font-size: 9px !important;
}

.terms-conditions-size-8 {
  font-size: 8px !important;
}
