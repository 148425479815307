.address {
  flex-grow: 1;
  flex-shrink: 0;
  font-style: normal;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
