.details {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.label {
  display: inline-block;
  width: 180px;
}
