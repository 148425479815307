.header {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &.DE {
    gap: 26px;
  }
}

.title {
  display: flex;
  justify-content: space-between;
}

.sub-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.full-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: 220px;
}
