.links {
  border-bottom: 1px solid var(--gray300);

  td {
    padding: 0 0 12px 24px;
    text-align: right;
    white-space: nowrap;

    &:first-child {
      text-align: left;
      white-space: normal;
    }

    .title {
      margin: 2px 0;
    }

    .description {
      color: var(--invoice-color-text-66);
    }

    p {
      margin: 0;
    }

    ul {
      padding: 0;
      list-style-type: none;
    }

    li,
    a {
      line-height: 16px;
      color: var(--content-accent);
      text-decoration: underline;
      cursor: pointer;
    }

    li:hover,
    a:hover {
      color: var(--content-accent);
    }
  }
}
