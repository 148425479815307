.credit-note, .invoice, .quote {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 708px;
  color: var(--invoice-color-text);
  word-wrap: anywhere;
  background-color: var(--primary-white);
  box-shadow: var(--shadow-high);

  --invoice-color-theme-05: rgb(from var(--invoice-color-theme) r g b / 0.05);
  --invoice-color-text-66: rgb(from var(--invoice-color-text) r g b / 0.66);
}

.credit-note, .invoice {
  min-height: 985px;
}

.quote {
  min-height: 894px;
}

.document {
  .layout {
    padding: 48px 48px 64px;

    .main {
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-top: 40px;
    }
  }

  :global(.label-1) {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }

  :global(.label-2) {
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 11px;
  }

  :global(.body-1) {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  :global(.body-1-bold) {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }

  :global(.body-2) {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
  }

  :global(.body-3) {
    font-size: 7.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 8px;
    word-wrap: break-word;
  }
}

.document.DE {
  .layout {
    padding: 67px 67px 40px 84px;

    .main {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 24px;
    }
  }

  :global(.label-1) {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }

  :global(.label-2) {
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 11px;
  }

  :global(.body-1) {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  :global(.body-1-bold) {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  :global(.body-2) {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
  }

  :global(.body-3) {
    font-size: 7.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 8px;
    word-wrap: break-word;
  }
}
