.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  border-radius: 10px;
  background-color: var(--elevation-high);
  color: var(--content-primary-a);
}

.products {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.priceNoteContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
