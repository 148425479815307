.header {
  display: flex;
  flex-direction: column;
}

.info {
  display: flex;
  flex-direction: column;
}

.info-avatar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.info-name {
  color: var(--content-secondary);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-amount-disabled {
  color: var(--content-tertiary);
  text-decoration: line-through;
}
