.container {
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: center;
  justify-content: center;
  width: 504px;
  height: 100%;
  color: var(--content-primary-a);
}

.form {
  width: 100%;
}

.input {
  margin-bottom: 40px;
}
