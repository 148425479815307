.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 0 12px;
  margin-bottom: 16px;
  background-color: var(--gray50);
  color: var(--content-primary-a-static);
}

.qrCodeContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.qrCode {
  width: 48px;
  height: 48px;
}

.paymentCta {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  height: 24px !important;
  padding: 4px 8px;
  font-size: 10px;
  border-radius: 4px;
  background: var(--content-primary-a-static);
  color: var(--content-primary-b-static);
  text-decoration: none;
}

.paymentCta svg {
  width: 10px;
  height: 10px;
}
