.avatar-wrapper {
  display: flex;
  position: relative;
  aspect-ratio: 1 / 1;

  &.size-16 {
    width: 16px;
  }

  &.size-24 {
    width: 24px;
  }

  &.size-28 {
    width: 28px;
  }

  &.size-32 {
    width: 32px;
  }

  &.size-40 {
    width: 40px;
  }

  &.size-48 {
    width: 48px;
  }

  &.size-56 {
    width: 56px;
  }

  &.size-64 {
    width: 64px;
  }

  &.avatar-borders {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid var(--border-avatar);
      box-sizing: border-box;
    }
  }

  &.avatar-disabled {
    opacity: 0.45;
  }
}

.avatar {
  width: 100%;
  object-fit: cover;
  background: var(--background-secondary);
  border-radius: 50%;
}

.avatar-content {
  width: 100%;
  background: var(--gray200);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--content-primary-a-static);
}

.avatar-initials {
  composes: caption from global;
  display: flex;
  color: var(--primary-black);
  align-items: center;
  justify-content: center;
  /* Using base token for consistency with backend-generated, theme-agnostic avatars */
  background-color: var(--plum100);
  color: var(--content-primary-a-static);
  border-radius: 50%;
}
