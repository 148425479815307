.data-table {
  width: 100%;
  border-spacing: 0;

  th,
  td {
    padding: 0;
    background-color: var(--content-primary-b);
  }
}

.table-row {
  &:has([data-is-dragging]) {
    position: relative;
  }

  &:has([data-is-dragging]) > .table-cell {
    z-index: 0 !important;
  }

  .cell-actions-display {
    display: none;
  }

  &:hover .cell-actions-display {
    display: flex;
  }
}

.table-row:hover .table-cell-hovered {
  background-color: var(--elevation-low);
}

.table-cell-scrolled {
  /* Clip the shadow on all sides except the right */
  clip-path: inset(0 -9999px 0 0);
  box-shadow: var(--shadow-high);
}
