.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px; /* 40px + 2x8px */
  padding: 8px;
}

.step {
  color: var(--content-primary-a);
}

.stepper {
  flex: 1;
  max-width: 672px;
}

.desktop {
  display: none;
}

@media screen and (min-width: 768px) {
  .container {
    height: 80px;
    padding: 0 20px;
  }

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }
}
