.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--content-primary-a);
}

.progressBar {
  width: 100%;
}

.brandedProgressBar {
  [data-percentage-progress-bar] {
    background: linear-gradient(
      90deg,
      var(--mint800) -11.46%,
      var(--plum800) 47.87%,
      var(--peach800) 107.2%
    );
    height: 6px;
  }
}

.progressChecks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  padding: 24px;
  width: 100%;
  background-color: var(--elevation-high);
  border-radius: 8px;
}
