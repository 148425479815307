.disclaimer {
  width: 100%;
  margin-top: 16px;
}

.topupLink {
  composes: body-link from global;
  padding: 0;
  color: var(--color-primary);

  &:hover {
    text-decoration: underline;
  }
}
