.cockpit-tile {
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-width: 256px;
  padding: 16px;
  overflow: hidden;
  color: var(--content-primary-a);
  text-align: left;
  background: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;

  &:where(button) {
    appearance: none;
    cursor: pointer;
    box-shadow: var(--shadow-low);

    &:hover {
      box-shadow: var(--shadow-high);
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px var(--border-accent-low);
    }
  }

  & p {
    overflow-wrap: break-word;
    margin: 0;

    &.title {
      margin-bottom: 8px;
    }

    &.subtitle {
      color: var(--content-secondary);

      & svg {
        color: currentColor;
      }
    }
  }
}

.cockpit-tile-width {
  max-width: 303px;
}

.value-loading {
  display: flex;
  align-items: center;
  height: 32px;
}

.subtitle-loading {
  display: flex;
  align-items: center;
  height: 24px;
}

.blur-text {
  filter: blur(7px);
}
