.divider {
  display: flex;
  align-items: center;
  color: var(--content-tertiary);
  text-align: center;
}

.divider::after,
.divider::before {
  flex: 1;
  content: '';
  border-top: 0.5px solid var(--border-secondary);
}

.divider:not(:empty)::before {
  margin-right: 10px;
}

.divider:not(:empty)::after {
  margin-left: 10px;
}
