.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 188px;
  height: 126px;
  margin-right: 4px;

  &.DE {
    height: 100px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: right top;
  }
}
