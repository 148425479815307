.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: var(--elevation-low);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 504px;
  text-align: center;
}

.success,
.actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.illustration {
  display: block;
  padding: 0;
  margin: 0;
}

.illustration svg {
  scale: 1.3;
}
