.container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.details {
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  flex: 1 0 0;
  color: var(--invoice-color-text-66);
}
