.supplier-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;

  /* avatars should not be tokenized - they are the same on light and dark mode */
  /* stylelint-disable style-lint-plugin-qonto/require-theme-token */
  color: var(--primary-black);
  text-transform: uppercase;
  background-color: var(--plum100);
  border-radius: 50%;
}

.gray-background {
  background-color: var(--background-secondary);
}
